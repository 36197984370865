/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  // {
  //   key: 'category',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'city-bikes', label: 'City bikes' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Category',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Category',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },

  {
    key: 'boothType',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'ipad', label: 'iPad' },
      { option: 'ipadCamera', label: 'iPad + Camera' },
      { option: 'pcCamera', label: 'PC + Camera' },
      // roamingBooth
      { option: 'roamingBooths', label: 'Roaming Booths' },
      { option: 'roamingPhotography', label: 'Roaming Photography' },
      // videoBooths
      { option: '360Video', label: '360' },

      { option: "other", label: "Other" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Booth Type',
      searchMode: 'has_all',
      group: 'primary',
    },
    showConfig: {
      label: 'Booth Type',
    },
    saveConfig: {
      label: 'Booth Type',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'ipad',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "mobiboothAura", label: "Mobibooth - Aura", parent: "ipad" },
      { option: "mobiboothCruisePlusRoamerStand", label: "Mobibooth - Cruise Plus Roamer + Stand", parent: "ipad" },
      { option: "boothmastersPicstationMini", label: "Picstation Mini (iPad Pro)", parent: "ipad" },
      { option: "pbscoSalsa", label: "PBSCO Salsa", parent: "ipad" }
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'ipad',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'iPad',
    },
    saveConfig: {
      label: 'iPad',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'ipadEquipment',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'appleIpadAnyNewerModelWithAUSB', label: "Apple iPad(any newer model with a USB-C port without a home button)Apple iPad Pro (with M1 chip or better) 4G/5G Hotspot (separate device or built into iPad)", parent: "ipad" },
      // { option: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "ipad" },
      // { option: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "ipad" },
      { option: "pairOfBatteryPoweredFillLightsOnMicStandsOnEitherSideOfBooth", label: "Pair of battery-powered fill lights on mic stands on either side of booth", parent: "ipad" },
      { option: "printerStationIncludesEverythingNeededForPrintingWithSnappic", label: "Printer Station –  Includes everything needed for printing with Snappic: DNP printer, 4x6 printer media, hotspot, and printer server computer running Snappic Print Server App)", parent: "ipad" },
      { option: "printerStand", label: "Printer Stand (no table needed from venue)", parent: "ipad" },
      { option: "videoSignageBuiltAuraView", label: "Video signage built into booth column (i.e. Aura View)", parent: "ipad" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'ipadEquipment',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'iPad Equipment',
    },
    saveConfig: {
      label: 'iPad Equipment',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'ipadCamera',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      // ipadCamera iPad + Camera
      { option: 'boothActiveMinibooth', label: 'BoothActive - Minibooth (w/iPad)', parent: "ipadCamera" },
      { option: 'everyboothICON', label: 'Everybooth - ICON', parent: "ipadCamera" },
      { option: 'maxDesignCurveDSLR', label: 'Max Design - Curve DSLR (iPad)', parent: "ipadCamera" },
      { option: 'mobiboothAuraM', label: 'Mobibooth - Aura M', parent: "ipadCamera" },
      { option: 'photoBoothSupplyCoGuac', label: 'Photo Booth Supply Co - Guac', parent: "ipadCamera" },
      { option: 'pictstationMini', label: 'Pictstation Mini - (iPad+DSLR Faceplate)', parent: "ipadCamera" },
      { option: 'yodaboothLite', label: 'Yodabooth - Lite (w/iPad)', parent: "ipadCamera" }
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'iPad + Camera',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'iPad + Camera',
    },
    saveConfig: {
      label: 'iPad + Camera',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'ipadCameraEquipment',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      // ipadCamera iPad + Camera
      { option: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "ipadCamera" },
      { option: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "ipadCamera" },
      { option: "pairOfBatteryPoweredFillLightsOnMicStandsOnEitherSideOfBooth", label: "Pair of battery-powered fill lights on mic stands on either side of booth", parent: "ipadCamera" },
      { option: "printerStation", label: "Printer Station -  Includes everything needed for printing with Snappic: DNP printer, 4x6 printer media, hotspot, and printer server computer running Snappic Print Server App)", parent: "ipadCamera" },
      { option: "printerStand", label: "Printer Stand (no table needed from venue)", parent: "ipadCamera" },
      { option: "videoSignageBooth", label: "Video signage built into booth column (i.e. Aura View)", parent: "ipadCamera" },
      // { option: "8x8 Tension Fabric Backdrop", label: "8x8 Tension Fabric Backdrop", parent: "ipadCamera" },
      { option: "external Studio Flash with Umbrella or Beauty Dish", label: "External Studio Flash with Umbrella or Beauty Dish", parent: "ipadCamera" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'iPad + Camera Equipment',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'iPad + Camera Equipment',
    },
    saveConfig: {
      label: 'iPad + Camera Equipment',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'pcCamera',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'boothActiveMinboothSurfacePro', label: 'BoothActive - Minbooth (Surface Pro)', parent: 'pcCamera' },
      { option: 'everyboothOriginalPC', label: 'Everybooth - Original (PC)', parent: 'pcCamera' },
      { option: 'everyboothEvolutionPC', label: 'Everybooth - Evolution (PC)', parent: 'pcCamera' },
      { option: 'lAPhotoPartyInfinitePC', label: 'LA Photo Party - Infinite (PC)', parent: 'pcCamera' },
      { option: 'lAPhotoPartyVenturePC', label: 'LA Photo Party - Venture (PC)', parent: 'pcCamera' },
      { option: 'maxDesignCurveDSLRSurfacePro', label: 'Max Design - Curve DSLR (Surface Pro)', parent: 'pcCamera' },
      { option: 'pSMCreativePicstationMiniSurfacePro', label: 'Picstation Mini (Surface Pro)', parent: 'pcCamera' },
      { option: 'pBSupplyCoLegacySurfacePro', label: 'PB Supply Co - Legacy (Surface Pro)', parent: 'pcCamera' },
      { option: 'yodaboothLiteSurfacePro', label: 'Yodabooth - Lite (Surface Pro)', parent: 'pcCamera' },
      { option: 'yodaboothPrimePC', label: 'Yodabooth - Prime (PC)', parent: 'pcCamera' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'PC + Camera',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'PC + Camera',
    },
    saveConfig: {
      label: 'PC + Camera',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'pcCameraEquipment',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: '4G/5GHotspotSeparateDevice)', label: '4G/5G Hotspot (separate device)', parent: 'pcCamera' },
      { option: 'externalStudioFlashUmbrellaBeautyDish', label: 'External Studio Flash with Umbrella or Beauty Dish', parent: 'pcCamera' },
      { option: 'pairBatteryPoweredLightsMicBooth', label: 'Pair of battery-powered fill lights on mic stands on either side of booth', parent: 'pcCamera' },
      { option: 'dnpPrinter620', label: 'DNP 620 Printer & 4x6 printer media', parent: 'pcCamera' },
      { option: 'printerStandNoTableNeededVenue', label: 'Printer Stand (no table needed from venue)', parent: 'pcCamera' },
      // { option: '8x8TensionFabricBackdrop', label: '8x8 Tension Fabric Backdrop', parent: 'pcCamera' },
      { option: 'photoBoothUploadLicenseOwner', label: 'Photo Booth Upload License Owner', parent: 'pcCamera' },
      { option: 'breezeBoothPCLicenseOwner', label: 'Breeze Booth (PC) License Owner', parent: 'pcCamera' },
      { option: 'darkroomBoothPCLicenseOwner', label: 'Darkroom Booth (PC) License Owner', parent: 'pcCamera' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'PC + Camera Equipment',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'PC + Camera Equipment',
    },
    saveConfig: {
      label: 'PC + Camera Equipment',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'roamingBooths',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "ataRingRoamer", label: "ATA - Ring Roamer", parent: "roamingBooths" },
      { option: "mobiboothCruise", label: "Mobibooth - Cruise", parent: "roamingBooths" },
      { option: "boothmastersRoamer", label: "Boothmasters Roamer", parent: "roamingBooths" },
      { option: "eventRoamer", label: "Event  Roamer", parent: "roamingBooths" },
      { option: "maxDesignMobileMaxRoamer", label: "Max Design - MobileMax Roamer", parent: "roamingBooths" }
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Roaming Booths',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Roaming Booths',
    },
    saveConfig: {
      label: 'Roaming Booths',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'roamingBoothsEquipment',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'appleIpadAnyNewerModelWithAUSB', label: 'Apple iPad, any newer model with a USB-C port and without a home button', parent: "roamingBooths" },
      { option: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "roamingBooths" },
      { option: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "roamingBooths" },
      { option: 'snappicCompatibleBluetoothRGBW', label: 'Snappic-compatible Bluetooth RGBW ring light', parent: "roamingBooths" },
      // { option: 'backupBatteryOnsite', label: 'Backup Battery On-site', parent: "roamingBooths" },
      { option: "operatedProfessionalPhotographer", label: 'Operated by staff that is a true professional photographer', parent: "roamingBooths" }
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Roaming Booths Equipment',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Roaming Booths Equipment',
    },
    saveConfig: {
      label: 'Roaming Booths Equipment',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'roamingPhotography',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "spotMyPhotosCameraFlash", label: "SpotMyPhotos (DSLR/Mirroless Camera/Flash w/iPhone)", parent: "roamingPhotography" },
      { option: "roamingPhotographyOther", label: "Other", parent: "roamingPhotography" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Roaming Photography',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Roaming Photography',
    },
    saveConfig: {
      label: 'Roaming Photography',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: 'roamingPhotographyEquipment',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'appleIphoneProMax12', label: 'Apple iPhone Pro Max (generation 12 and newer)', parent: "roamingPhotography" },
      { option: 'appleIpadAnyNewerModelWithAUSB', label: 'Apple iPad, any newer model with a USB-C port and without a home button', parent: "roamingPhotography" },
      { option: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "roamingPhotography" },
      { option: 'printerStationIncludesSpotMyPhotos', label: 'Printer Station -  Includes everything needed for printing with SpotMyPhotos: DNP printer, 4x6 printer media, and computer running SpotMyPhotos Print Server App)', parent: "roamingPhotography" },
      // { option: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "roamingPhotography" },
      { option: 'backupBatteryOnsite', label: 'Backup Battery On-site', parent: "roamingPhotography" },
      { option: "Operated by staff that is a true professional photographer", label: 'Operated by staff that is a true professional photographer', parent: "roamingPhotography" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Roaming Photography Equipment',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Roaming Photography Equipment',
    },
    saveConfig: {
      label: 'Roaming Photography Equipment',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: '360Video',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'atlantaPhotoBooth40.5Venue360', label: 'Atlanta Photo Booth - 40.5in Venue 360', parent: '360Video' },
      { option: "dZENtech34in45inwithiPadBluetoothOldPC", label: `DZENtech - 34in or 45in with iPad/Bluetooth (Old models needing direct PC connection  should be listed in "Other")`, parent: '360Video' },
      { option: 'Mobibooth31.5inMobiSpin360', label: `Mobibooth - 31.5” MobiSpin360`, parent: '360Video' },
      { option: 'OrcaVue34inAir', label: `OrcaVue - 34” Air`, parent: '360Video' },
      { option: 'OrcaVue39inAirorGala', label: `39” Air or 39”Gala`, parent: '360Video' },
      { option: 'OrcaVue46inAirorXL', label: `OrcaVue - 46” Air or 46” XL`, parent: '360Video' },
      { option: 'PBSCO32Tortilla', label: `PBSCO - 32” Tortilla`, parent: '360Video' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: '360',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: '360',
    },
    saveConfig: {
      label: '360',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  {
    key: '360VideoEquipment',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'appleiPhoneProMaxGeneration12andNewer', label: 'Apple iPhone Pro Max (generation 12 and newer)', parent: '360Video' },
      { option: 'armMountedLEDRinglight', label: 'Arm-mounted LED Ring light', parent: '360Video' },
      // { option: 'anynewerModelWithUSBCPortWithoutHomeButton', label: ' any newer model with a USB-C port and without a home button', parent: '360Video' },
      // { option: 'pEDGOBiColorLEDRinglightLG-R320C', label: 'LEDGO Bi-Color LED Ringlight (LG-R320C)', parent: '360Video' },
      { option: 'appleIPadProM1Chip', label: 'Apple iPad Pro with M1 chip or better', parent: '360Video' },
      { option: 'canonMirrorless120FPSiOS', label: 'Canon mirrorless with at least 120FPS slow motion direct-connected to iOS device', parent: '360Video' },
      // { option: 'platformMinimumDiameter39andLarger', label: 'Platform Minimum Diameter: 39" and larger', parent: '360Video' },
      { option: 'snappicBluetoothConnectivity', label: 'Snappic Bluetooth Connectivity', parent: '360Video' },
      { option: 'backupControlWiredWirelessRemote', label: 'Backup Control with wired/wireless remote', parent: '360Video' },
      { option: 'motorizedPlatformArmNonManual', label: 'Motorized Platform Arm (not manual)', parent: '360Video' },
      { option: 'batteryPoweredPlatform', label: 'Battery-powered platform', parent: '360Video' },
      { option: '4XBatteryPoweredLEDLightsMicStandsSurroundingPlatform', label: '4X Battery-Powered LED lights on mic stands surrounding platform', parent: '360Video' },
      { option: 'landscapeandPortraitCameraOrientation', label: 'Landscape and Portrait Camera Orientation', parent: '360Video' },
      { option: 'stanchionsSurroundSetupArea', label: 'Stanchions to surround setup area', parent: '360Video' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: '360 Equipment',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: '360 Equipment',
    },
    saveConfig: {
      label: '360 Equipment',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

export const guestAttrire = [
  { key: "business", label: "Business" },
  { key: "casual", label: "Casual" },
  { key: "formal", label: "Formal" },
];
export const staffAttrire = [
  { key: "provided", label: "Provided" },
  { key: "business", label: "Business" },
  { key: "casual", label: "Casual" },
  { key: "formal", label: "Formal" },
];
export const deliveryTimeFrame = [
  { key: "1 Hour", label: "1 Hour Before" },
  { key: "2 Hours", label: "2 Hours Before" },
  { key: "3 Hours", label: "3 Hours Before" },
  { key: "4 Hours", label: "4 Hours Before" },
  { key: "5 Hours", label: "5 Hours Before" }
];

export const vendorParkings = [
  { key: "freeParking", label: "Free Parking" },
  { key: "validatedParking", label: "Validated Parking" },
  { key: "parkingFee", label: "Parking Fee" },
  { key: "toBeDetermined", label: "To Be Determined" },
]

export const timeArray = [
  { key: "00:00", label: "12:00 am" },
  { key: "01:00", label: "01:00 am" },
  { key: "02:00", label: "02:00 am" },
  { key: "03:00", label: "03:00 am" },
  { key: "04:00", label: "04:00 am" },
  { key: "05:00", label: "05:00 am" },
  { key: "06:00", label: "06:00 am" },
  { key: "07:00", label: "07:00 am" },
  { key: "08:00", label: "08:00 am" },
  { key: "09:00", label: "09:00 am" },
  { key: "10:00", label: "10:00 am" },
  { key: "11:00", label: "11:00 am" },
  { key: "12:00", label: "12:00 pm" },
  { key: "13:00", label: "01:00 pm" },
  { key: "14:00", label: "02:00 pm" },
  { key: "15:00", label: "03:00 pm" },
  { key: "16:00", label: "04:00 pm" },
  { key: "17:00", label: "05:00 pm" },
  { key: "18:00", label: "06:00 pm" },
  { key: "19:00", label: "07:00 pm" },
  { key: "20:00", label: "08:00 pm" },
  { key: "21:00", label: "09:00 pm" },
  { key: "22:00", label: "10:00 pm" },
  { key: "23:00", label: "11:00 pm" },
];

export const eventSetting = [
  { key: "indoors", label: "Indoors" },
  { key: "outdoors", label: "Outdoors" },
  { key: "determined", label: "To Be Determined" },
]

export const prongPowerOutlet = [
  { key: "notApplicableRoamingPhotography", label: "Not Applicable - Roaming Photography" },
  { key: "electricalCord", label: "Electrical cord run provided to exact setup location" },
  { key: "electrical25ft", label: "Electrical outlet available within 25ft" },
  { key: "electrical50ft", label: "Electrical outlet available within 50ft" },
  { key: "noElectricalavailable", label: "No electrical available - Provider's system must operate on battery power" },
]

export const backdropPreference = [
  { key: "noBackdrop", label: "No backdrop" },
  { key: "clientProvidingBackdrop", label: "Client providing backdrop" },
  { key: "photoBoothCompanyToProvideBackdrop", label: "Photo booth company to provide backdrop" },
  { key: "customBackdrop", label: "Custom Backdrop" },
]

// Categories
// Roaming : "Roaming Booths,Roaming Photography,Photo Attendant"
// Photo Booths : "iPad,iPad + Camera,PC + Camera,Photo Attendant"
// Video Booths :  "360,Photo Attendant"

// Software Companies
// iPad/iOS/MacOS Booths : "Snappic,"
// PC/Windows Booths : "Photo Booth Upload"

export const yesNoOptions = [
  { key: "yes", label: "Yes" },
  { key: "no", label: "No" },
]

export const booths = [
  { key: "photoBooths", label: "Photo Booths", parent: null },
  { key: "roamingBooth", label: "Roaming Booths", parent: null },
  { key: "videoBooths", label: "Video Booths", parent: null },
  { key: "other", label: "", parent: null }
]

export const boothTypes = [
  // photoBooths
  { key: 'ipad', label: 'iPad', parent: "photoBooths" },
  { key: 'ipadCamera', label: 'iPad + Camera', parent: "photoBooths" },
  { key: 'pcCamera', label: 'PC + Camera', parent: "photoBooths" },
  // roamingBooth
  { key: 'roamingBooths', label: 'Roaming Booths', parent: "roamingBooth" },
  { key: 'roamingPhotography', label: 'Roaming Photography', parent: "roamingBooth" },
  // videoBooths
  { key: '360Video', label: '360', parent: "videoBooths" },

  { key: "other", label: "Other - Please describe", parent: "other" },
];

export const boothTypeChilds = [
  // ipad
  { key: "mobiboothAura", label: "Mobibooth - Aura", parent: "ipad" },
  { key: "mobiboothCruisePlusRoamerStand", label: "Mobibooth - Cruise Plus Roamer + Stand", parent: "ipad" },
  { key: "boothmastersPicstationMini", label: "Picstation Mini (iPad Pro)", parent: "ipad" },
  { key: "pbscoSalsa", label: "PBSCO Salsa", parent: "ipad" },
  { key: "ipadOther", label: "Other - Please provide make/model/size/specs in the notes section", parent: "ipad" },

  // ipadCamera iPad + Camera
  { key: 'boothActiveMinibooth', label: 'BoothActive - Minibooth (w/iPad)', parent: "ipadCamera" },
  { key: 'everyboothICON', label: 'Everybooth - ICON', parent: "ipadCamera" },
  { key: 'maxDesignCurveDSLR', label: 'Max Design - Curve DSLR (iPad)', parent: "ipadCamera" },
  { key: 'mobiboothAuraM', label: 'Mobibooth - Aura M', parent: "ipadCamera" },
  { key: 'photoBoothSupplyCoGuac', label: 'Photo Booth Supply Co - Guac', parent: "ipadCamera" },
  { key: 'pictstationMini', label: 'Pictstation Mini - (iPad+DSLR Faceplate)', parent: "ipadCamera" },
  { key: 'yodaboothLite', label: 'Yodabooth - Lite (w/iPad)', parent: "ipadCamera" },
  { key: 'ipadCameraOther', label: 'Other - Please provide make/model/size/specs in notes section', parent: "ipadCamera" },

  // pcCamera == 
  { key: 'boothActiveMinboothSurfacePro', label: 'BoothActive - Minbooth (Surface Pro)', parent: 'pcCamera' },
  { key: 'everyboothOriginalPC', label: 'Everybooth - Original (PC)', parent: 'pcCamera' },
  { key: 'everyboothEvolutionPC', label: 'Everybooth - Evolution (PC)', parent: 'pcCamera' },
  { key: 'lAPhotoPartyInfinitePC', label: 'LA Photo Party - Infinite (PC)', parent: 'pcCamera' },
  { key: 'lAPhotoPartyVenturePC', label: 'LA Photo Party - Venture (PC)', parent: 'pcCamera' },
  { key: 'maxDesignCurveDSLRSurfacePro', label: 'Max Design - Curve DSLR (Surface Pro)', parent: 'pcCamera' },
  { key: 'pSMCreativePicstationMiniSurfacePro', label: 'Picstation Mini (Surface Pro)', parent: 'pcCamera' },
  { key: 'pBSupplyCoLegacySurfacePro', label: 'PB Supply Co - Legacy (Surface Pro)', parent: 'pcCamera' },
  { key: 'yodaboothLiteSurfacePro', label: 'Yodabooth - Lite (Surface Pro)', parent: 'pcCamera' },
  { key: 'yodaboothPrimePC', label: 'Yodabooth - Prime (PC)', parent: 'pcCamera' },
  { key: 'pcCameraOther', label: 'Other - Please provide make/model/size/specs in notes section', parent: 'pcCamera' },

  //  roamingBooth == 
  { key: "ataRingRoamer", label: "ATA - Ring Roamer", parent: "roamingBooths" },
  { key: "mobiboothCruise", label: "Mobibooth - Cruise", parent: "roamingBooths" },
  { key: "boothmastersRoamer", label: "Boothmasters Roamer", parent: "roamingBooths" },
  { key: "eventRoamer", label: "Event  Roamer", parent: "roamingBooths" },
  { key: "maxDesignMobileMaxRoamer", label: "Max Design - MobileMax Roamer", parent: "roamingBooths" },
  // { key: "maxRoamer", label: "MAX - Roamer", parent: "roamingBooths" },
  { key: "roamingBoothsOther", label: "Other - Please provide make/model/size/specs in notes section", parent: "roamingBooths" },

  // roamingPhotography == 
  { key: "spotMyPhotosCameraFlash", label: "SpotMyPhotos (DSLR/Mirroless Camera/Flash w/iPhone)", parent: "roamingPhotography" },
  { key: "roamingPhotographyOther", label: "Other", parent: "roamingPhotography" },

  // 360 == 
  { key: 'atlantaPhotoBooth40.5Venue360', label: 'Atlanta Photo Booth - 40.5in Venue 360', parent: '360Video' },
  { key: "dZENtech34in45inwithiPadBluetoothOldPC", label: `DZENtech - 34in or 45in with iPad/Bluetooth (Old models needing direct PC connection  should be listed in "Other")`, parent: '360Video' },
  { key: 'Mobibooth31.5inMobiSpin360', label: `Mobibooth - 31.5” MobiSpin360`, parent: '360Video' },
  { key: 'OrcaVue34inAir', label: `OrcaVue - 34” Air`, parent: '360Video' },
  { key: 'OrcaVue39inAirorGala', label: `39” Air or 39”Gala`, parent: '360Video' },
  { key: 'OrcaVue46inAirorXL', label: `OrcaVue - 46” Air or 46” XL`, parent: '360Video' },
  { key: 'PBSCO32Tortilla', label: `PBSCO - 32” Tortilla`, parent: '360Video' },
  { key: '360VideoOther', label: 'Other', parent: '360Video' }
];

export const boothTypeEquipments = [
  // ipad
  { key: 'appleIpadAnyNewerModelWithAUSB', label: "Apple iPad(any newer model with a USB-C port without a home button)Apple iPad Pro (with M1 chip or better) 4G/5G Hotspot (separate device or built into iPad)", parent: "ipad" },
  // { key: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "ipad" },
  // { key: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "ipad" },
  { key: "pairOfBatteryPoweredFillLightsOnMicStandsOnEitherSideOfBooth", label: "Pair of battery-powered fill lights on mic stands on either side of booth", parent: "ipad" },
  { key: "printerStationIncludesEverythingNeededForPrintingWithSnappic", label: "Printer Station –  Includes everything needed for printing with Snappic: DNP printer, 4x6 printer media, hotspot, and printer server computer running Snappic Print Server App)", parent: "ipad" },
  { key: "printerStand", label: "Printer Stand (no table needed from venue)", parent: "ipad" },
  { key: "videoSignageBuiltAuraView", label: "Video signage built into booth column (i.e. Aura View)", parent: "ipad" },
  // { key: "8x8TensionFabricBackdrop", label: "8x8 Tension Fabric Backdrop", parent: "ipad" },
  // {key:"ipadOther", label:"Other - Please explain in notes section",parent:"ipad"},

  // ipadCamera == 
  { key: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "ipadCamera" },
  { key: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "ipadCamera" },
  { key: "pairOfBatteryPoweredFillLightsOnMicStandsOnEitherSideOfBooth", label: "Pair of battery-powered fill lights on mic stands on either side of booth", parent: "ipadCamera" },
  { key: "printerStation", label: "Printer Station -  Includes everything needed for printing with Snappic: DNP printer, 4x6 printer media, hotspot, and printer server computer running Snappic Print Server App)", parent: "ipadCamera" },
  { key: "printerStand", label: "Printer Stand (no table needed from venue)", parent: "ipadCamera" },
  { key: "videoSignageBooth", label: "Video signage built into booth column (i.e. Aura View)", parent: "ipadCamera" },
  // { key: "8x8 Tension Fabric Backdrop", label: "8x8 Tension Fabric Backdrop", parent: "ipadCamera" },
  { key: "external Studio Flash with Umbrella or Beauty Dish", label: "External Studio Flash with Umbrella or Beauty Dish", parent: "ipadCamera" },
  // {key:"ipadCameraOther", label:"Other - Please explain in notes section",parent:"ipadCamera"},

  // pcCamera == 
  { key: '4G/5GHotspotSeparateDevice)', label: '4G/5G Hotspot (separate device)', parent: 'pcCamera' },
  { key: 'externalStudioFlashUmbrellaBeautyDish', label: 'External Studio Flash with Umbrella or Beauty Dish', parent: 'pcCamera' },
  { key: 'pairBatteryPoweredLightsMicBooth', label: 'Pair of battery-powered fill lights on mic stands on either side of booth', parent: 'pcCamera' },
  { key: 'dnpPrinter620', label: 'DNP 620 Printer & 4x6 printer media', parent: 'pcCamera' },
  { key: 'printerStandNoTableNeededVenue', label: 'Printer Stand (no table needed from venue)', parent: 'pcCamera' },
  // { key: '8x8TensionFabricBackdrop', label: '8x8 Tension Fabric Backdrop', parent: 'pcCamera' },
  { key: 'photoBoothUploadLicenseOwner', label: 'Photo Booth Upload License Owner', parent: 'pcCamera' },
  { key: 'breezeBoothPCLicenseOwner', label: 'Breeze Booth (PC) License Owner', parent: 'pcCamera' },
  { key: 'darkroomBoothPCLicenseOwner', label: 'Darkroom Booth (PC) License Owner', parent: 'pcCamera' },

  // roamingBooth

  // { key: 'appleIphoneProMax12', label: 'Apple iPhone Pro Max (generation 12 and newer)', parent: "roamingBooths" },
  { key: 'appleIpadAnyNewerModelWithAUSB', label: 'Apple iPad, any newer model with a USB-C port and without a home button', parent: "roamingBooths" },
  { key: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "roamingBooths" },
  { key: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "roamingBooths" },
  { key: 'snappicCompatibleBluetoothRGBW', label: 'Snappic-compatible Bluetooth RGBW ring light', parent: "roamingBooths" },
  // { key: 'backupBatteryOnsite', label: 'Backup Battery On-site', parent: "roamingBooths" },
  { key: "operatedProfessionalPhotographer", label: 'Operated by staff that is a true professional photographer', parent: "roamingBooths" },
  // {key:"roamingBoothOther", label:"Other - Please explain in notes section",parent:"roamingBooths"},

  // roamingPhotography == ""
  { key: 'appleIphoneProMax12', label: 'Apple iPhone Pro Max (generation 12 and newer)', parent: "roamingPhotography" },
  { key: 'appleIpadAnyNewerModelWithAUSB', label: 'Apple iPad, any newer model with a USB-C port and without a home button', parent: "roamingPhotography" },
  { key: '4G&5GHotspot', label: '4G/5G Hotspot (separate device or built in to iPad)', parent: "roamingPhotography" },
  { key: 'printerStationIncludesSpotMyPhotos', label: 'Printer Station -  Includes everything needed for printing with SpotMyPhotos: DNP printer, 4x6 printer media, and computer running SpotMyPhotos Print Server App)', parent: "roamingPhotography" },
  // { key: 'appleIpadProWithM1ChipOrBetter', label: 'Apple iPad Pro with M1 chip or better', parent: "roamingPhotography" },
  { key: 'backupBatteryOnsite', label: 'Backup Battery On-site', parent: "roamingPhotography" },
  { key: "Operated by staff that is a true professional photographer", label: 'Operated by staff that is a true professional photographer', parent: "roamingPhotography" },
  // {key:"roamingPhotographyOther", label:"Other - Please explain in notes section",parent:"roamingPhotography"},

  // 360 == "Apple iPhone Pro Max (generation 12 and newer),Apple iPad, any newer model with a USB-C port and without a home button,LEDGO Bi-Color LED Ringlight (LG-R320C),Apple iPad Pro with M1 chip or better,Platform Minimum Diameter: 39” and larger,Snappic Bluetooth Connectivity,Backup Control with wired/wireless remote,Motorized Platform Arm (not manual),Battery-powered platform,4X Battery-Powered LED lights on mic stands surrounding platform,Landscape and Portrait Camera Orientation

  // { key: 'appleiPad', label: 'Apple iPad', parent: '360' },
  { key: 'appleiPhoneProMaxGeneration12andNewer', label: 'Apple iPhone Pro Max (generation 12 and newer)', parent: '360Video' },
  { key: 'armMountedLEDRinglight', label: 'Arm-mounted LED Ring light', parent: '360Video' },
  // { key: 'anynewerModelWithUSBCPortWithoutHomeButton', label: ' any newer model with a USB-C port and without a home button', parent: '360Video' },
  // { key: 'pEDGOBiColorLEDRinglightLG-R320C', label: 'LEDGO Bi-Color LED Ringlight (LG-R320C)', parent: '360Video' },
  { key: 'appleIPadProM1Chip', label: 'Apple iPad Pro with M1 chip or better', parent: '360Video' },
  { key: 'canonMirrorless120FPSiOS', label: 'Canon mirrorless with at least 120FPS slow motion direct-connected to iOS device', parent: '360Video' },
  // { key: 'platformMinimumDiameter39andLarger', label: 'Platform Minimum Diameter: 39" and larger', parent: '360Video' },
  { key: 'snappicBluetoothConnectivity', label: 'Snappic Bluetooth Connectivity', parent: '360Video' },
  { key: 'backupControlWiredWirelessRemote', label: 'Backup Control with wired/wireless remote', parent: '360Video' },
  { key: 'motorizedPlatformArmNonManual', label: 'Motorized Platform Arm (not manual)', parent: '360Video' },
  { key: 'batteryPoweredPlatform', label: 'Battery-powered platform', parent: '360Video' },
  { key: '4XBatteryPoweredLEDLightsMicStandsSurroundingPlatform', label: '4X Battery-Powered LED lights on mic stands surrounding platform', parent: '360Video' },
  { key: 'landscapeandPortraitCameraOrientation', label: 'Landscape and Portrait Camera Orientation', parent: '360Video' },
  { key: 'stanchionsSurroundSetupArea', label: 'Stanchions to surround setup area', parent: '360Video' },
];

export const systemsOwned = [
  { key: 'ipadBoothsOlderModelsWithHomeButtons', label: 'iPad Booths (older models with home buttons)' },
  { key: '360photoBoothsWithSnappicIPhone12ProOrNewer', label: '360 Photo Booths with Snappic + iPhone 12 Pro or newer' },
  { key: 'ipadBoothsiPadProModelsWithoutHomeButtons', label: 'iPad Booths (iPad Pro models WITHOUT home buttons)' },
  { key: '360photoBoothsWithWindowsPCsGoProsEtc', label: '360 Photo Booths with windows PCs, GoPros, etc' },
  { key: 'ipadDslrMirrorlessBooths(snappicDirectConnectSupport)', label: 'iPad + DSLR/Mirrorless Booths (Snappic direct connect support)' },
  { key: 'multicameraArraySystems', label: 'Multicamera Array Systems' },
  { key: 'windowsPcDslrMirrorlessBooths', label: 'Windows PC + DSLR/Mirrorless Booths' },
  { key: 'gimbalMountedVideoBooths', label: 'Gimbal-mounted Video booths' },
  { key: 'roamingBooths', label: 'Roaming Booths' },
  { key: 'cameraRobotsGlamBots', label: 'Camera Robots - Glam Bots' },
  { key: '360photoBoothsWithSnappicIPadProM1orNewer', label: '360 Photo Booths with Snappic + iPad Pro M1 or newer' },
  { key: 'otherPleaseShareDetailsInNotesSection', label: 'Other - Please share details in notes section' },
];

export const mirrorLessPhotoBooths = [
  { key: "minboothIpadDslrMirrorless", label: "Minbooth - iPad + DSLR/Mirrorless" },
  { key: "mobiboothAuraMWithCanonM50", label: "Mobibooth Aura M with Canon M50" },
  { key: "boothmastersPicstationMini(ipadDslrMirrorless)", label: "Boothmasters Picstation Mini (iPad + DSLR/Mirrorless)" },
  { key: "maxCurveIpadDslrMirrorless", label: "MAX - Curve - iPad + DSLR/Mirrorless" },
  { key: "pbscoGuac", label: "PBSCO Guac" },
  { key: "pbscoLegacyWithIpadDslrMirrorless", label: "PBSCO Legacy with iPad + DSLR/Mirrorless" },
  { key: "otherPleaseExplainInNotesSection", label: "Other - Please explain in notes section" },
];

export const roamingPhotoBooths = [
  { key: "ataRingRoamer", label: "ATA - Ring Roamer" },
  { key: "mobiboothCruise", label: "Mobibooth - Cruise" },
  { key: "boothmastersRoamer", label: "Boothmasters Roamer" },
  { key: "maxRoamer", label: "MAX - Roamer" },
  { key: "otherPleaseExplainInNotesSection", label: "Other - Please explain in notes section" },
];

export const windowPCPhotoBooths = [
  { key: "minboothSurfaceProDSLR", label: "Minbooth - Surface Pro + DSLR" },
  { key: "laPhotoPartyInfinite", label: "LA Photo Party - Infinite" },
  { key: "laPhotoPartyVenture", label: "LA Photo Party - Venture" },
  { key: "pbscoLegacyBooth(SurfaceProDslr)", label: "PBSCO - Legacy Booth (Surface Pro + DSLR)" },
  { key: "yodaboothPrime", label: "Yodabooth - Prime" },
  { key: "yodaboothLite", label: "Yodabooth - Lite" },
  { key: "maxCurve(SurfaceProDslr)", label: "MAX - Curve (Surface Pro + DSLR)" },
  { key: "otherPCBoothPleaseExplainInNotesSection", label: "Other PC Booth - Please explain in notes section" },
];

export const windowPCSoftwareLicenses = [
  { key: "laPhotoPartyPhotoPartyUpload(Sharing)", label: "LA Photo Party - Photo Party Upload (Sharing)" },
  { key: "laPhotoPartyPhotoBoothUpload", label: "LA Photo Party - Photo Booth Upload" },
  { key: "breezeBooth(akaDslrRemotePro)", label: "Breeze Booth (AKA DSLR Remote Pro)" },
  { key: "breezeDslrMultiCameraForArrays", label: "Breeze DSLR Multi Camera for Arrays" },
  { key: "darkroomBooth", label: "Darkroom Booth" },
  { key: "dslrBooth", label: "DSLR Booth" },
  { key: "otherWindowsSoftwarePleaseExplainInNotesSection", label: "Other Windows Software - Please explain in notes section" },
];

export const photoBooth360 = [
  { key: 'orcavue34AirPlatform', label: 'Orcavue 34" Air Platform' },
  { key: 'orcavue39AirOrGalaPlatform', label: 'Orcavue 39" Air or Gala Platform' },
  { key: 'orcavue46AirOrXlPlatform', label: 'Orcavue 46" Air or XL Platform' },
  { key: 'dzentech360Platform(mustHaveBluetoothSnappicIntegration)', label: 'Dzentech 360 Platform (MUST HAVE Bluetooth Snappic Integration)' },
  { key: "mobibooth360Platform", label: "Mobibooth 360 Platform" },
  { key: "atlantaPhotoBooth360Platform", label: "Atlanta Photo Booth 360 Platform" },
  { key: "other360PlatformPleaseExplainInNotesSection", label: "Other 360 Platform - Please explain in notes section" },
];

export const EventType = [
  { key: "barBatMitzvah", label: "Bar/Bat Mitzvah", url: "https://res.cloudinary.com/wle/image/upload/v1712647171/eventImages/Bar_Bat_Mitzvah_1_pk4rqr.jpg" },
  { key: "concertMusicFestival", label: "Concert / Music Festival", url: "https://res.cloudinary.com/wle/image/upload/v1712647175/eventImages/Concert___Music_Festival_ghlcd9.png" },
  { key: "conferenceTradeShow", label: "Conference / Trade Show", url: "https://res.cloudinary.com/wle/image/upload/v1712647166/eventImages/Conference___Trade_Show_1_mvyd1y.jpg" },
  { key: "corporateEvent", label: "Corporate Event", url: "https://res.cloudinary.com/wle/image/upload/v1712645358/eventImages/Corporate_Event_1_wqhbqe.jpg" },
  { key: "holidayParty", label: "Holiday Party", url: "https://res.cloudinary.com/wle/image/upload/v1712647169/eventImages/Holiday_Party_1_ixdphb.jpg" },
  { key: "multiDayEvent", label: "Multi-Day Event (explain in notes section)", url: "https://res.cloudinary.com/wle/image/upload/v1712647172/eventImages/Multi-Day_Event_1_dfrnnv.jpg" },
  { key: "privateParty", label: "Private Party", url: "https://res.cloudinary.com/wle/image/upload/v1712646009/eventImages/Private_Party_1_tca67c.jpg" },
  { key: "quinceanera", label: "Quinceañera", url: "https://res.cloudinary.com/wle/image/upload/v1712647170/eventImages/Quincean%CC%83era_1_pzugcj.jpg" },
  { key: "schoolDance", label: "School Dance", url: "https://res.cloudinary.com/wle/image/upload/v1712645345/eventImages/School_Dance_1_lmjhia.jpg" },
  { key: "sportingEvent", label: "Sporting Event", url: "https://res.cloudinary.com/wle/image/upload/v1712647167/eventImages/Sporting_Event_1_misnv5.jpg" },
  { key: "wedding", label: "Wedding", url: "https://res.cloudinary.com/wle/image/upload/v1712647163/eventImages/Wedding_1_jx5qgb.jpg" },
  { key: "other", label: "Other (explain in notes section)", url: "https://res.cloudinary.com/wle/image/upload/v1712647165/eventImages/Other_1_nkbo4c.jpg" }
];

export const DeliveryDetails = [
  { key: "front", label: "front" },
  { key: "back", label: "back" },
  { key: "loading dock", label: "loading dock" },
  { key: "other", label: "other" }
];

export const citiesInAmerica = [
  { option: "New York", label: "New York" },
  { option: "Los Angeles", label: "Los Angeles" },
  { option: "Chicago", label: "Chicago" },
  { option: "Houston", label: "Houston" },
  { option: "Philadelphia", label: "Philadelphia" },
  { option: "San Diego", label: "San Diego" },
  { option: "New Jersey", label: "New Jersey" },
];

// <<<<<<<<<<<<<<< filters >>>>>>>>>>>>>>>>>> 
// boothType
// boothTypeChilds.ipad
// boothTypeChilds.ipadEquipment
// boothTypeChilds.ipadCamera
// boothTypeChilds.ipadCameraEquipment
// boothTypeChilds.pcCamera
// boothTypeChilds.pcCameraEquipment
// boothTypeChilds.roamingBooths
// boothTypeChilds.roamingBoothsEquipment
// boothTypeChilds.roamingPhotography
// boothTypeChilds.roamingPhotographyEquipment
// boothTypeChilds.360Video
// boothTypeChilds.360VideoEquipment

// address1.search  >>>> location serach

// typeOfListing:"business"
// transactionProcessAlias:"default-accept-bid/release-1" 
// unitType:"item"
// listingType:"default-accept-bid"



const eventPublicSchema = {
  listingType: "daily-booking",
  transactionProcessAlias: "default-booking/release-1",
  unitType: "day",


  eventBid: "",
  typeOfListing: "event",


  // current User 
  address1: "",
  companyName: "",
  website: "",


  // detail tab >>>> EventDetailsForm  
  title: "text",
  eventType: "select",
  eventTypeText: "sub child of eventType text",
  eventDate: "date string",
  deliveryTimeFrame: "selct string",
  photoBoothStartTime: "selct string",
  photoBoothEndTime: "selct string",
  estimatedAttendance: "number",
  softwareName: "string",

  // location >>>> EditListingVenueDetails
  locationName: "string",
  venueAddress: "",
  roomName: "",
  boothSetIndoorOutdoor: "",
  vendorParking: "",
  staffParking: "",
  staffAttrie:"",

  // services EditListingBoothDetailsPanel
  boothType: "array",
  boothTypeChilds: "object",
  boothTypeOther: "text",

  // Planning details EditListingScheduleForm
  venueName: "text",
  eventPlannerName: "text",
  eventPlannerCell: "text",
  deliveryDetails: "text",
  parkingDetails: "text",
  roomPlacement: "text",
  prongPowerOutlet: "text",
  tableProvided: "text",
  softwareEventCode: "text",
  softwareEventPin: "text",
  finalEventNotes: "text",
  roomPlacement: "text",
}

const eventPrivateSchema = {
  batch_id: "string this is used a added a schedule mail if user is not completed the listing we trigger mail to user",
  batch_ids: "Array this is used a added a schedule mail if user is not completed the listing we trigger mail to user",
  pageCompleted: "detail location services planning",
  published: "boolean"
};

const transactionSchema = {
  "processName": "default-bid" || "default-accept-bid ",
  "transitions": [
    {
      "transition": "transition/inquire",
      "createdAt": "2024-07-18T11:02:57.265Z",
      "by": "customer"
    }
  ],
  "payoutTotal": {
    "_sdkType": "Money",
    "amount": 190000,
    "currency": "USD"
  },
  "processVersion": 11,
  "createdAt": "2024-07-18T11:02:57.221Z",
  "lastTransitionedAt": "2024-07-18T11:02:57.265Z",
  "protectedData": {
    "eventStartDateUnix": 1721352600,
    "bidPriceMail": "2000.00",
    "businessListing": {
      "publicData": {},
      "title": "Business | Ipad | London"
    },
    "eventListingId": "6698a3ca-8428-4209-b073-5835fcb9962c",
    "eventListing": {
      "publicData": {},
      "title": "RFP | Flight | 07/19/2024 | George ltd  | Salisbury"
    },
    "eventDateMail": "07/19/2024",
    "boothTypeMail": "ipad",
    "eventEndDateUnix": 1721359800,
    "VenueNameMail": "RFP | Flight | 07/19/2024 | George ltd  | Salisbury",
    "businessListingId": "6698f5c0-4a8e-40de-80e7-b4b7876cd767",
    "bid": {
      "adminCustomerCommisionMaybe": 0,
      "adminProviderCommissionMaybe": 0,
      "currency": "USD",
      "note": "",
      "price": 200000,
      "tax": 0
    }
  },
  "lineItems": [
    {
      "code": "line-item/item",
      "unitPrice": {
        "_sdkType": "Money",
        "amount": 200000,
        "currency": "USD"
      },
      "lineTotal": {
        "_sdkType": "Money",
        "amount": 200000,
        "currency": "USD"
      },
      "reversal": false,
      "includeFor": [
        "customer",
        "provider"
      ],
      "quantity": {
        "_sdkType": "BigDecimal",
        "value": "1"
      }
    },
    {
      "code": "line-item/provider-commission",
      "unitPrice": {
        "_sdkType": "Money",
        "amount": 200000,
        "currency": "USD"
      },
      "lineTotal": {
        "_sdkType": "Money",
        "amount": -10000,
        "currency": "USD"
      },
      "reversal": false,
      "includeFor": [
        "provider"
      ],
      "percentage": {
        "_sdkType": "BigDecimal",
        "value": "-5"
      }
    },
    {
      "code": "line-item/customer-commission",
      "unitPrice": {
        "_sdkType": "Money",
        "amount": 200000,
        "currency": "USD"
      },
      "lineTotal": {
        "_sdkType": "Money",
        "amount": 10000,
        "currency": "USD"
      },
      "reversal": false,
      "includeFor": [
        "customer"
      ],
      "percentage": {
        "_sdkType": "BigDecimal",
        "value": "5"
      }
    }
  ],
  "lastTransition": "transition/inquire",
  "payinTotal": {
    "_sdkType": "Money",
    "amount": 210000,
    "currency": "USD"
  }
}

export const algoliaEvent = {
  "_geoloc": {
    "lat": 40.70865,
    "lng": -73.987625
  },
  "title": "RFP | Single Match | 08/01/2024 | Marketplace Studio | New York City",
  "description": null,
  "price": null,
  "address1": {
    "search": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
    "selectedPlace": {
      "address": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
      "city": "New York City",
      "origin": {
        "lat": 40.74846650000001,
        "lng": -73.985542
      },
      "postcode": "10118",
      "region": "New York"
    }
  },
  "boothType": [
    "360Video",
    "roamingBooths",
    "ipad"
  ],
  "boothTypeChilds": {
    "360Video": [
      "OrcaVue34inAir"
    ],
    "360VideoAttendant": 1,
    "360VideoBudget": "1000",
    "360VideoEquipment": [
      "canonMirrorless120FPSiOS",
      "batteryPoweredPlatform"
    ],
    "360VideoQuantity": 1,
    "360VideobackdropPreference": "noBackdrop",
    "ipad": [
      "mobiboothCruisePlusRoamerStand"
    ],
    "ipadAttendant": 1,
    "ipadBudget": "500",
    "ipadCameraAttendant": 1,
    "ipadCameraQuantity": 1,
    "ipadCamerabackdropPreference": "noBackdrop",
    "ipadEquipment": [
      "pairOfBatteryPoweredFillLightsOnMicStandsOnEitherSideOfBooth"
    ],
    "ipadQuantity": 1,
    "ipadbackdropPreference": "noBackdrop",
    "otherAttendant": 1,
    "otherQuantity": 1,
    "otherbackdropPreference": "noBackdrop",
    "pcCameraAttendant": 1,
    "pcCameraQuantity": 1,
    "pcCamerabackdropPreference": "noBackdrop",
    "roamingBooths": [
      "maxDesignMobileMaxRoamer",
      "mobiboothCruise"
    ],
    "roamingBoothsAttendant": 1,
    "roamingBoothsBudget": "2000",
    "roamingBoothsEquipment": [
      "appleIpadAnyNewerModelWithAUSB",
      "appleIpadProWithM1ChipOrBetter"
    ],
    "roamingBoothsQuantity": 1,
    "roamingBoothsbackdropPreference": "noBackdrop",
    "roamingPhotographyAttendant": 1,
    "roamingPhotographyQuantity": 1,
    "roamingPhotographybackdropPreference": "noBackdrop"
  },
  "boothTypeOther": "",
  "city": "New York City",
  "companyName": "Marketplace Studio",
  "deliveryTimeFrame": "2 Hours",
  "estimatedAttendance": "12",
  "eventBid": "open",
  "eventDate": "2024-08-01",
  "eventEndDateUnix": 1722508200,
  "eventStartDateUnix": 1722499200,
  "eventTitle": "Single Match",
  "eventType": "conferenceTradeShow",
  "listingType": "daily-booking",
  "locationName": "NYCU",
  "photoBoothEndTime": "08/01/2024 06:30 AM",
  "photoBoothStartTime": "04:00 AM",
  "postcode": "",
  "region": "New York",
  "roomName": "Hall",
  "softwareName": "LookingGlasses",
  "transactionProcessAlias": "default-booking/release-1",
  "typeOfListing": "event",
  "unitType": "day",
  "venueAddress": {
    "predictions": [],
    "search": "Brooklyn Bridge, Brooklyn Bridge, New York City, New York, United States",
    "selectedPlace": {
      "address": "Brooklyn Bridge, Brooklyn Bridge, New York City, New York, United States",
      "bounds": {
        "_sdkType": "LatLngBounds",
        "ne": {
          "lat": 40.72661322741643,
          "lng": -73.96392228698669
        },
        "sw": {
          "lat": 40.69068061633357,
          "lng": -74.01132454113831
        }
      },
      "city": "New York City",
      "origin": {
        "lat": 40.708646921875,
        "lng": -73.9876234140625
      },
      "postcode": "",
      "region": "New York"
    }
  },
  "user": {
    "address1": {
      "search": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
      "selectedPlace": {
        "address": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
        "city": "New York City",
        "origin": {
          "lat": 40.74846650000001,
          "lng": -73.985542
        },
        "postcode": "10118",
        "region": "New York"
      }
    },
    "bankruptcy": "no",
    "companyDefendant": "no",
    "companyName": "Marketplace Studio",
    "companyPled": "no",
    "dbaName": "Marketplace Photos",
    "hires": [
      {}
    ],
    "instagram": "@marketplacestudio.io",
    "insurance": "no",
    "insuranceURLYes": false,
    "jobs": [
      {}
    ],
    "savedInAlgolia": true,
    "userType": "provider",
    "yearOfExp": "10",
    "zipCodeRadius": [
      {
        "origin": {
          "lat": 40.630733,
          "lng": -81.332783
        },
        "primaryZip": "10119=8",
        "serviceRadius": "120700"
      }
    ],
    "firstName": "Darren",
    "lastName": "Cody",
    "email": "dcody@marketplacestudio.io",
    "displayName": "Darren C",
    "id": "66a7035e-8c50-4200-a594-0e6e16d7b4ee"
  },
  "objectID": "66a995aa-51f6-4bf5-ba47-a30b50746fda"
};


const algoliaBussinessListingSchema= {
  "_geoloc": {
    "lat": 40.748466,
    "lng": -73.98554
  },
  "title": "Business | Doe's Great Pictures | New York City",
  "description": null,
  "price": null,
  "360Video": [
    "dZENtech34in45inwithiPadBluetoothOldPC",
    "OrcaVue39inAirorGala"
  ],
  "360VideoEquipment": [
    "canonMirrorless120FPSiOS",
    "batteryPoweredPlatform"
  ],
  "address1": {
    "search": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
    "selectedPlace": {
      "address": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
      "city": "New York City",
      "origin": {
        "lat": 40.74846650000001,
        "lng": -73.985542
      },
      "postcode": "10118",
      "region": "New York"
    }
  },
  "backDropPackages": "no",
  "boothType": [
    "ipad",
    "roamingBooths",
    "360Video"
  ],
  "boothTypeChilds": {
    "360Video": [
      "dZENtech34in45inwithiPadBluetoothOldPC",
      "OrcaVue39inAirorGala"
    ],
    "360VideoEquipment": [
      "canonMirrorless120FPSiOS",
      "batteryPoweredPlatform"
    ],
    "360VideoQuantity": 1,
    "ipad": [
      "pbscoSalsa",
      "mobiboothCruisePlusRoamerStand"
    ],
    "ipadCameraQuantity": 1,
    "ipadEquipment": [
      "pairOfBatteryPoweredFillLightsOnMicStandsOnEitherSideOfBooth",
      "printerStand"
    ],
    "ipadQuantity": 1,
    "otherQuantity": 1,
    "pcCameraQuantity": 1,
    "roamingBooths": [
      "maxDesignMobileMaxRoamer",
      "mobiboothCruise"
    ],
    "roamingBoothsEquipment": [
      "appleIpadAnyNewerModelWithAUSB",
      "appleIpadProWithM1ChipOrBetter"
    ],
    "roamingBoothsQuantity": 1,
    "roamingPhotographyQuantity": 1
  },
  "city": "New York City",
  "companyName": "Doe's Great Pictures",
  "dbaName": "Pictures by Jane",
  "ipad": [
    "pbscoSalsa",
    "mobiboothCruisePlusRoamerStand"
  ],
  "ipadCamera": [],
  "ipadCameraEquipment": [],
  "ipadEquipment": [
    "pairOfBatteryPoweredFillLightsOnMicStandsOnEitherSideOfBooth",
    "printerStand"
  ],
  "isAdminApproved": false,
  "listingType": "default-accept-bid",
  "pcCamera": [],
  "pcCameraEquipment": [],
  "postcode": "10118",
  "propPackages": "no",
  "propPackagesYes": [
    ""
  ],
  "region": "New York",
  "roamingBooths": [
    "maxDesignMobileMaxRoamer",
    "mobiboothCruise"
  ],
  "roamingBoothsEquipment": [
    "appleIpadAnyNewerModelWithAUSB",
    "appleIpadProWithM1ChipOrBetter"
  ],
  "roamingPhotography": [],
  "roamingPhotographyEquipment": [],
  "scrapbookPackages": "no",
  "transactionProcessAlias": "default-accept-bid/release-1",
  "typeOfListing": "business",
  "unitType": "item",
  "zipCodeRadius": [
    {
      "origin": {
        "lat": 40.7482554,
        "lng": -73.9848875
      },
      "primaryZip": "10118",
      "serviceRadius": "80467"
    }
  ],
  "user": {
    "address1": {
      "search": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
      "selectedPlace": {
        "address": "Empire State Building, 350 5th Ave, New York City, New York 10118, United States",
        "city": "New York City",
        "origin": {
          "lat": 40.74846650000001,
          "lng": -73.985542
        },
        "postcode": "10118",
        "region": "New York"
      }
    },
    "bankruptcy": "no",
    "companyDefendant": "no",
    "companyName": "Doe's Great Pictures",
    "companyPled": "no",
    "dbaName": "Pictures by Jane",
    "hires": [
      {}
    ],
    "instagram": "Darren",
    "insurance": "no",
    "insuranceURLYes": false,
    "jobs": [
      {}
    ],
    "userType": "provider",
    "yearOfExp": "4",
    "zipCodeRadius": [
      {
        "origin": {
          "lat": 40.7482554,
          "lng": -73.9848875
        },
        "primaryZip": "10118",
        "serviceRadius": "80467"
      }
    ],
    "firstName": "Jane",
    "lastName": "Doe",
    "email": "dcody+janedoe@marketplacestudio.io",
    "displayName": "Jane D",
    "id": "66a7060e-79bf-4c27-9630-015acec73986"
  },
  "objectID": "66a7066e-aaff-4d3a-b6c0-558b14ef79e5"
};


//  ipad , ipadEquipment , ipadCamera, ipadCameraEquipment  , pcCamera, pcCameraEquipment
//  roamingBooths,roamingBoothsEquipment , roamingPhotography,roamingPhotographyEquipment
//  360Video, 360VideoEquipment 